import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Outlet, Link } from 'react-router-dom';

import { IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../../assets/images/logo.svg';

import Footer from '../Shared/Footer';

const links = [
  {
    text: 'Privacy Policy',
    url: '/privacy-policy',
  },
  {
    text: 'Terms & Conditions',
    url: '/terms-and-conditions',
  },
];

const NavLink = ({ url, text }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(location.pathname.includes(url));
  }, [location.pathname]);

  return (
    <>
      <Link
        to={url}
        className={cx('font-light', { 'text-primary-main': isActive })}>
        {text}
      </Link>
    </>
  );
};

const MobileSideMenu = ({ isOpen, setIsOpen }) => {
  return (
    <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)}>
      <div className='w-80 py-12 px-6 text-white'>
        <div className='flex flex-col items-end gap-4'>
          {links.map(link => {
            return (
              <div onClick={() => setIsOpen(false)}>
                <NavLink {...link} />
              </div>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default function DocumentLayout() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  return (
    <>
      <header>
        <nav className='flex items-center gap-4 h-16 px-4'>
          <div className='grow'>
            <div className='flex'>
              <Link to='/' className='my-auto'>
                <img src={logo} alt='logo' className='w-32' />
              </Link>
            </div>
          </div>
          <IconButton
            className='md:hidden'
            onClick={() => setIsSideMenuOpen(true)}>
            <MenuIcon className='text-primary-main text-4xl' />
          </IconButton>
          <MobileSideMenu
            isOpen={isSideMenuOpen}
            setIsOpen={setIsSideMenuOpen}
          />
        </nav>
      </header>
      <div className='container mx-auto px-4 min-h-screen mt-8'>
        <div className='flex flex-col md:flex-row gap-4'>
          <aside className='w-full md:min-w-[17rem] md:max-w-[17rem] md:block hidden'>
            <div className='bg-background-paper p-4 rounded-lg'>
              <div className='flex flex-col gap-3'>
                {links.map(link => {
                  return <NavLink {...link} />;
                })}
              </div>
            </div>
          </aside>
          <main className='grow mb-20'>
            <Outlet />
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}
