import { Link } from 'react-router-dom';

// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
// import MailIcon from '@mui/icons-material/Mail';

import logo from '../../assets/images/logo.svg';

import telegram from '../../assets/icons/socials/telegram-white.png';
import twitter from '../../assets/icons/socials/twitter-white.png';
import instagram from '../../assets/icons/socials/instagram-white.png';
// import facebook from "../../assets/icons/socials/facebook-white.png"

const footerLinks = [
  {
    text: 'Home',
    url: '/#home',
  },
  {
    text: 'Privacy Policy',
    url: '/privacy-policy',
  },
  {
    text: 'Contact Us',
    url: '#contact',
  },
  {
    text: 'Terms & Conditions',
    url: '/terms-and-conditions ',
  },
];

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className='bg-background-paper 2xl:container mx-auto p-4'>
        <div className='flex items-center md:items-start gap-6 justify-between container mx-auto'>
          <div className=''>
            <Link to='/' className='true'>
              <img src={logo} alt='logo' className='w-40' />
            </Link>
            <p className='text-[8px] md:text-sm font-light'>
              KCASA, {year} All Rights Reserved.
            </p>
          </div>
          <ul className='flex flex-wrap items-center justify-center gap-y-2 gap-x-3  md:gap-8 my-auto'>
            {/* <p className="text-lg text-primary-main mb-3">Contact Us</p>
                        <div className="flex items-center gap-2 mb-1">
                            <LocationOnIcon className="text-primary-main" />
                            <p className="text-sm font-light">New York, USA</p>
                        </div>
                        <div className="flex items-center gap-2 mb-1">
                            <PhoneInTalkIcon className="text-primary-main" />
                            <p className="text-sm font-light">Call Us: +18984545454</p>
                        </div>
                        <div className="flex items-center gap-2 mb-1">
                            <MailIcon className="text-primary-main" />
                            <p className="text-sm font-light">admin@realestate.com</p>
                        </div> */}
            {footerLinks.map((link, i) => {
              return (
                <li key={i} className='font-light'>
                  {link.url.includes('#') ? (
                    <a
                      href={link.url}
                      className='text-sm md:text-base text-primary-main'>
                      {link.text}
                    </a>
                  ) : (
                    <Link
                      to={link.url}
                      className='text-sm md:text-base text-primary-main'>
                      {link.text}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
          <div className=''>
            <p className='text-lg text-primary-main mb-3 text-center hidden md:block'>
              Follow Us On
            </p>
            <div className='flex flex-wrap items-center justify-center gap-4'>
              <a href='https://t.me/kcasa_official_group'>
                <img src={telegram} alt='' className='w-6 md:w-8' />
              </a>
              <a href='https://twitter.com/kcasa_io'>
                <img src={twitter} alt='' className='w-6 md:w-8' />
              </a>
              <a href='https://www.instagram.com/kcasa.io/'>
                <img src={instagram} alt='' className='w-6 md:w-8' />
              </a>
              {/* <img src={facebook} alt="" className="w-6 md:w-8" /> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
