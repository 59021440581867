import { lazy } from 'react';

// project imports
import DefaultLayout from '../components/Layouts/DefaultLayout';
import Loadable from './../components/Loaders/Loadable';
import TopLoader from '../components/Loaders/TopLoader';

const HomePage = Loadable(lazy(() => import('../pages/HomePage')), TopLoader);
const PropertiesPage = Loadable(lazy(() => import('../pages/PropertiesPage')), TopLoader);
const PropertyDetailsPage = Loadable(lazy(() => import('../pages/PropertyDetailsPage')), TopLoader);
const QRPage = Loadable(lazy(() => import('../pages/QRPage')), TopLoader);
const WhyInvestInDubaiPage = Loadable(lazy(() => import('../pages/WhyInvestInDubaiPage')), TopLoader);

// ==============================|| MAIN ROUTING ||============================== //

const DefaultRoutes = [
    {
        path: '',
        element: <DefaultLayout />,
        children: [
            {
                path: '',
                element: <HomePage />
            },
            {
                path: 'properties',
                element: <PropertiesPage />
            },
            {
                path: 'properties/:id',
                element: <PropertyDetailsPage />
            },
            {
                path: 'invest-in-dubai',
                element: <WhyInvestInDubaiPage />
            },
        ]
    },
    {
        path: 'qr',
        element: <QRPage />
    }
]

export default DefaultRoutes;
