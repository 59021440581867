export default function compStyleOverrides(themePalette) {
	return {
		MuiAccordion: {
			styleOverrides: {
				root: {
					marginBottom: '16px',
					borderRadius: '6px',
					':first-of-type': {
						borderRadius: '6px',
					},
					':last-of-type': {
						borderRadius: '6px',
					},
					backgroundColor: themePalette.background.paper,
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					color: themePalette.primary.main,
				},

				expandIconWrapper: {
					color: themePalette.primary.main,
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					'& .MuiTypography-root': {
						color: themePalette.primary.text,
						fontSize: '14px'
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fieldSet: {
						display: "none"
					},
					borderWidth: "2px",
					borderRadius: '0.5rem',
					borderColor: themePalette.primary["main"],
					background: themePalette.background["paper"],
					color: themePalette.primary["main"],
					"&.Mui-focused": {
						outline: "none",
						borderColor: themePalette.primary["main"],
						boxShadow: "none"
					},
					"& .MuiSvgIcon-root": {
						color: themePalette.primary["main"],
					},
					"& .MuiSelect-select": {
						padding: "0rem",
						textAlign: "left"
					},
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					"& .MuiSvgIcon-root": {
						color: themePalette.primary.light,
					},
					"&.Mui-checked": {
						"& .MuiSvgIcon-root": {
							color: themePalette.primary.main
						}
					}
				}
			}
		}
	}
}
