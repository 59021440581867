import { lazy } from 'react';

// project imports
import DocumentLayout from '../components/Layouts/DocumentLayout';
import Loadable from './../components/Loaders/Loadable';
import TopLoader from '../components/Loaders/TopLoader';

const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicyPage')), TopLoader);
const TermsAndConditionsPage = Loadable(lazy(() => import('../pages/TermsAndConditionsPage')), TopLoader);

const DocumentRoutes = {
    path: '',
    element: <DocumentLayout />,
    children: [
        {
            path: 'privacy-policy',
            element: <PrivacyPolicyPage />
        },
        {
            path: 'terms-and-conditions',
            element: <TermsAndConditionsPage />
        },
    ]
}

export default DocumentRoutes;
