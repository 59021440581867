import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Drawer, IconButton, MenuItem, Popover } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuIcon from '@mui/icons-material/Menu';

import logo from "../../assets/images/logo.svg";
import flag1 from "../../assets/icons/flags/flag1.png";
import flag2 from "../../assets/icons/flags/flag2.png";
import flag3 from "../../assets/icons/flags/flag3.png";
import flag4 from "../../assets/icons/flags/flag4.png";

const navLinks = [
    {
        text: "Home",
        url: '/#home'
    },
    // {
    //     text: "Properties",
    //     url: '/#'
    // },
    {
        text: "Ecosystem",
        url: '/#ecosystem'
    },
    {
        text: "FAQ",
        url: '/#faq'
    },
    {
        text: "Contact Us",
        url: '#contact'
    },
]

const langs = [
    {
        flag: flag1
    },
    {
        flag: flag2
    }, {
        flag: flag3
    }, {
        flag: flag4
    },
]

const LangSelect = () => {
    const [currLang, setCurrLang] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button onClick={handleClick}>
                <div className="flex items-center gap-1">
                    <img src={langs[currLang].flag} alt="" style={{ width: '34px', height: '21px' }} />
                    {Boolean(anchorEl) ? <ArrowDropUpIcon className="text-primary-main" /> : <ArrowDropDownIcon className="text-primary-main" />}
                </div>
            </button>
            <Popover
                // id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {langs.map((lang, i) => {
                    return (
                        <MenuItem key={i} onClick={() => { setCurrLang(i); handleClose() }}><img src={lang.flag} alt="" /></MenuItem>
                    )
                })}
            </Popover>
        </>
    )
}

const MobileSideMenu = ({ isOpen, setIsOpen }) => {
    return (
        <Drawer
            anchor='right'
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <div className="w-80 py-12 px-6">
                <ul className="flex flex-col items-end gap-4">
                    {navLinks.map((link, i) => {
                        return (
                            <li key={i} className="font-light" onClick={() => setIsOpen(false)}>
                                {link.url.includes("#") ? (
                                    <a href={link.url} className="text-primary-main">
                                        {link.text}
                                    </a>
                                ) : (
                                    <Link to={link.url} className="text-primary-main">
                                        {link.text}
                                    </Link>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Drawer>
    )
}

export default function Navbar() {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

    const scrollLimit = 64;
    const [overScrollLimit, setOverScrollLimit] = useState(false);

    const fixHeaderOnScroll = () => {
        setOverScrollLimit(window.scrollY > scrollLimit)
    }

    useEffect(() => {
        window.addEventListener('scroll', fixHeaderOnScroll);

        return () => window.removeEventListener('scroll', fixHeaderOnScroll)
    }, [])

    return (
        <>
            <div id="home" className={cx({ "h-16": overScrollLimit })}></div>
            <header className={cx({ "fixed w-full top-0 bg-background-default/90 z-50": overScrollLimit })}>
                <nav className="flex items-center justify-between md:justify-start gap-2 container mx-auto h-16 px-4">
                    {/* <div className="flex items-center md:hidden">
                        <LangSelect />
                    </div> */}
                    <div className="md:grow">
                        <div className="flex">
                            <Link to="/" className="my-auto">
                                <img src={logo} alt="logo" className="w-32" />
                            </Link>
                        </div>
                    </div>
                    <div className="items-center gap-6 hidden md:flex">
                        <ul className="flex items-center gap-8">
                            {navLinks.map((link, i) => {
                                return (
                                    <li key={i} className="font-light" >
                                        {link.url.includes("#") ? (
                                            <a href={link.url} className="text-primary-main">
                                                {link.text}
                                            </a>
                                        ) : (
                                            <Link to={link.url} className="text-primary-main">
                                                {link.text}
                                            </Link>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                        {/* <ul className="flex items-center gap-3">
                            {langs.map((lang, i) => {
                                return (
                                    <li key={i} >
                                        <img src={lang.flag} alt={"flag"} />
                                    </li>
                                )
                            })}
                        </ul> */}
                    </div>
                    <IconButton className="md:hidden" onClick={() => setIsSideMenuOpen(true)}><MenuIcon className="text-primary-main text-4xl" /></IconButton>
                    <MobileSideMenu isOpen={isSideMenuOpen} setIsOpen={setIsSideMenuOpen} />
                </nav>
            </header>
        </>
    )
}